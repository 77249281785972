import React, { FC, useState, useEffect } from 'react';
import { TableContainer, makeStyles, TableBody, TableHead, TableRow, TableCell } from '@material-ui/core';
import { HeaderRow, TableCustom } from 'components/Table';
import BodyRow from './components/BodyRow';
import SubBodyRow from './components/SubBodyRow';
import axios, { CancelTokenSource } from 'axios';
import { ACCOUNT_CATEGORY_BASE_URL } from 'constants/url';

const useStyles = makeStyles({
  container: {
    marginLeft: '20px'
  }
});

interface Props {
  indexCollapse: number;
  openCollapse: boolean;
  accountCategories: AccountCategoryModel[];
  subAccountCategories: AccountCategoryModel[];
  isLoadingData: boolean;
  wareHouses: WareHouseModel[];
  queryString?: string;
  order: 'asc' | 'desc';
  orderBy: string;
  setOrder: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setOrderBy: React.Dispatch<React.SetStateAction<string>>;
  handleConfirmationDelete: (id: number) => React.MouseEventHandler;
  onUpdateSubAccount: (id: number) => React.MouseEventHandler;
  handleOnUpdate: (id: number) => React.MouseEventHandler;
  handleOpenCollapse: (index: number, id: number) => React.MouseEventHandler;
}

const dummyAccountCategory: AccountCategoryModel = {
  id: 0,
  name: '',
  category: '',
  number: 0,
  subAccount: 0,
  isEditable: true,
  subItemAccount: []
};

const AccountCategoriesTable: FC<Props> = props => {
  const {
    indexCollapse,
    openCollapse,
    accountCategories,
    isLoadingData,
    wareHouses,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    queryString,
    handleOpenCollapse,
    handleConfirmationDelete,
    onUpdateSubAccount,
    handleOnUpdate
  } = props;

  const classes = useStyles();
  const [id, setId] = useState<number>(0);
  const [showSkeleton, setShowSkeleton] = useState<boolean>(false);
  const [subItemAccount, setSubItemAccount] = useState<AccountCategoryModel[]>([]);
  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getSubItemAccount = async (id: number) => {
    const cancelTokenSource: CancelTokenSource = axios.CancelToken.source();

    try {
      const { data } = await axios.get(`${ACCOUNT_CATEGORY_BASE_URL}/akun/${id}`, { cancelToken: cancelTokenSource.token });
      setSubItemAccount(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id !== 0) {
      getSubItemAccount(id);
    }
    if (isLoadingData) {
      setShowSkeleton(true);
    }
    return () => {
      setShowSkeleton(false);
    };
  }, [id, isLoadingData, accountCategories]);

  return (
    <TableContainer>
      <TableCustom>
        <TableHead>
          <HeaderRow
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headers={[{ label: '#' }, { id: 'name', label: 'Nama Akun', sort: false }]}
          />
        </TableHead>
        <TableBody>
          {showSkeleton ? (
            [0, 1, 2, 3].map(index => (
              <BodyRow
                handleOpenCollapse={handleOpenCollapse(index, 0)}
                indexCollapse={indexCollapse}
                openCollapse={openCollapse}
                index={index}
                key={index + 1}
                setId={setId}
                isLoading={showSkeleton}
                accountCategorie={dummyAccountCategory}
                onDelete={handleConfirmationDelete(0)}
                onUpdate={handleOnUpdate(0)}
              />
            ))
          ) : accountCategories && accountCategories.length > 0 ? (
            accountCategories.map((value, index) => (
              <>
                <BodyRow
                  index={index}
                  indexCollapse={indexCollapse}
                  openCollapse={openCollapse}
                  key={index + 1}
                  setId={setId}
                  isLoading={showSkeleton}
                  accountCategorie={value}
                  onDelete={handleConfirmationDelete(value.id)}
                  onUpdate={handleOnUpdate(index)}
                  handleOpenCollapse={handleOpenCollapse(index, value.id)}
                />
                {openCollapse && index === indexCollapse && subItemAccount.length > 0
                  ? subItemAccount.map((value, index) => (
                      <SubBodyRow
                        index={index}
                        indexCollapse={indexCollapse}
                        openCollapse={openCollapse}
                        key={index + 1}
                        setId={setId}
                        isLoading={showSkeleton}
                        accountCategorie={value}
                        onDelete={handleConfirmationDelete(value.id)}
                        onUpdateSubAccount={onUpdateSubAccount(value.id)}
                        handleOpenCollapse={handleOpenCollapse(index, value.id)}
                      />
                    ))
                  : null}
              </>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={4} align='center'>
                {queryString ? 'No matching result' : 'Data Tidak Tersedia.'}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </TableCustom>
    </TableContainer>
  );
};

export default AccountCategoriesTable;
